<template>
    <div class="bg-white flex">
        <div class="my-auto sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select
                @change="navigateTo"
                id="tabs"
                name="tabs"
                class="block w-full focus:ring-cobalt-500 focus:border-cobalt-500 border-grey-300 rounded-md"
            >
                <template v-for="tab in tabs" :key="tab.name">
                    <!--                    <option-->
                    <!--                        :selected="tab.current"-->
                    <!--                        :value="JSON.stringify(tab.to)"-->
                    <!--                        class="font-semibold"-->
                    <!--                    >-->
                    <!--                        {{ tab.name }}-->
                    <!--                    </option>-->
                    <optgroup v-if="tab.children?.length" :label="tab.name">
                        <option v-for="child in tab.children" :value="JSON.stringify(child.to)">
                            {{ child.name }}
                        </option>
                    </optgroup>
                    <option
                        v-else
                        :selected="tab.current"
                        :value="JSON.stringify(tab.to)"
                        class="font-semibold"
                    >
                        {{ tab.name }}
                    </option>
                </template>
            </select>
        </div>
        <div class="hidden sm:block h-full">
            <nav class="-mb-px flex space-x-8 h-full" aria-label="Tabs">
                <router-link
                    v-for="tab in tabs"
                    :key="tab.name"
                    :to="tab.to"
                    :aria-current="tab.current ? 'page' : undefined"
                    v-slot="{ isActive }"
                >
                    <div
                        :class="[
                            isActive
                                ? 'border-cobalt-500 text-cobalt-600'
                                : 'border-transparent text-grey-600 hover:text-grey-700 hover:border-grey-300',
                            'group inline-flex items-center py-4 px-1 border-b-4 font-medium text-sm h-full',
                        ]"
                    >
                        <FeatherWrapper :icon="tab.icon" class="h-5 w-5 -ml-0.5 mr-2" />
                        <span>{{ tab.name }}</span>
                        <div
                            v-if="tab.label"
                            class="ml-2 -mt-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-pacific-500 text-white"
                        >
                            {{ tab.label }}
                        </div>
                    </div>
                </router-link>
            </nav>
        </div>
    </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

export default {
    props: { tabs: Array },
    components: { FeatherWrapper },
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const store = useStore(); // Get Vuex store

        const buildVersion = computed(() => store.getters.buildVersion); // Get build version

        const getTabs = () =>
            props.tabs
                .filter((tab) => !(buildVersion.value === "dnanalysis" && tab.name === "Book")) // 🔥 Filter out "Book"
                .map((tab) => ({
                    ...tab,
                    current: route.matched.map((r) => r.path).includes(tab.to.path),
                }));

        const tabs = ref(getTabs());

        watch(
            () => [route.path, buildVersion.value], // 🔥 Watch buildVersion changes
            () => {
                tabs.value = getTabs();
            },
        );

        return {
            tabs,
            navigateTo: (event) => {
                router.push(JSON.parse(event.target.value));
            },
        };
    },
};
</script>
