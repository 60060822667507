<template>
    <div
        class="w-full xl:w-1/2"
        v-if="buildVersion !== 'dnanalysis'"
    >
        <TestStatus
            class="w-full"
            v-if="vitaminSubscriptionStatus"
            @click="open = true"
            :status="vitaminSubscriptionStatus.name"
        >
            <template #name>
                <div class="flex items-center">
                    <FeatherWrapper icon="truck" class="mr-2" />
                    Vitamin subscription
                </div>
            </template>
            <template v-slot:status="{ colours }">
                <p
                    class="my-0 p-2 inline-block rounded font-semibold text-sm"
                    :class="`${colours.bgClass} ${colours.textClass}`"
                    style="white-space: nowrap"
                >
                    {{ vitaminSubscriptionStatus.name }}
                </p>
            </template>
        </TestStatus>
        <TransitionRoot as="template" :show="open">
            <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
                <div
                    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
                >
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                    >
                        <DialogOverlay
                            class="fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity"
                        />
                    </TransitionChild>

                    <span
                        class="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >&#8203;</span>
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6"
                        >
                            <h1 class="mt-16 px-12 text-xl text-grey-700">
                                Your vitamin subscription
                            </h1>
                            <div class="px-12 pb-4">
                                <div
                                    v-if="
                                        ['expiringSoon', 'active'].includes(
                                            vitaminSubscriptionStatus.slug
                                        )
                                    "
                                >
                                    You can visit your dashboard and manage your subscription
                                    <a
                                        href="https://livingdna.getvitaminlab.com/account"
                                        target="_blank"
                                        rel="noopener"
                                    >here</a>.
                                </div>
                                <div v-else>
                                    Visit
                                    <router-link
                                        :to="{
                                            name: 'product',
                                            params: { slug: 'Personalised-vitamins' },
                                        }"
                                    >here</router-link>
                                    to add a vitamin subscription.
                                </div>
                            </div>
                            <table v-if="steps" class="mx-12 text-lg font-semibold mb-12 w-full">
                                <thead>
                                <tr class="text-base text-grey-600">
                                    <td colspan="3">Status</td>
                                    <td class="hidden md:table-cell">Usually takes</td>
                                </tr>
                                </thead>
                                <CompletedRow
                                    :key="step.name"
                                    v-for="step in steps.completeSteps"
                                    :step="step"
                                />
                                <InProgressRow
                                    :key="step.name"
                                    v-for="step in steps.activeSteps"
                                    :step="step"
                                />
                                <IncompleteRow
                                    :key="step.name"
                                    v-for="step in steps.incompleteSteps"
                                    :step="step"
                                />
                                <FailedRow
                                    :key="step.name"
                                    v-for="step in steps.failedSteps"
                                    :step="step"
                                />
                            </table>
                        </div>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<script>
import store from "@/store/store";
// eslint-disable-next-line no-use-before-define
const buildVersion = computed(() => store.getters.buildVersion); // Reactive getter for buildVersion
console.log('felipe',buildVersion.value);
import axios from "axios";
import endpoints from "@/endpoints";
import moment from "moment";
import { vitaminSubscription } from "@/views/dashboard/kitMachine";
import TestStatus from "../TestStatus.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import CompletedRow from "@/views/dashboard/StatusBar/CompletedRow.vue";
import InProgressRow from "@/views/dashboard/StatusBar/InProgressRow.vue";
import IncompleteRow from "@/views/dashboard/StatusBar/IncompleteRow.vue";
import FailedRow from "@/views/dashboard/StatusBar/FailedRow.vue";
import {computed, ref} from "vue";
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";

export default {
    components: {
        FailedRow,
        IncompleteRow,
        InProgressRow,
        CompletedRow,
        TestStatus,
        FeatherWrapper,
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    },
    setup() {
        const open = ref(false);
        return { open };
    },
    data() {
        return {
            vitaminSubscriptionStatus: undefined,
            steps: undefined,
            buildVersion: store.getters.buildVersion || "default", // Default fallback
        };
    },
    async created() {
        const res = await axios.get(
            `${endpoints.ecommerce}/product/profile/${this.$store.getters.profile.barcode}`
        );
        const subscriptionData = res.data;
        console.log("Build Version:", this.buildVersion);

        this.vitaminSubscriptionStatus = this.getVitaminSubscriptionStatus(subscriptionData);
        this.steps = vitaminSubscription[this.vitaminSubscriptionStatus.slug];
    },
    state: {
        buildVersion: null, // Default value
    },
    getters: {
        buildVersion: (state) => state.buildVersion,
    },
    mutations: {
        setBuildVersion(state, version) {
            console.log(version);
            state.buildVersion = version;
        },
    },
    actions: {
        initializeBuildVersion({ commit }) {
            const version = process.env.VUE_APP_BUILD_VERSION || 'default'; // Example source
            commit('setBuildVersion', version);
        },
    },
    methods: {
        handleVitaminClick() {
            console.log(this.steps);
            this.open = true;
        },
        getVitaminSubscriptionStatus(subscriptionData) {
            if (subscriptionData.length === 0) {
                return {
                    name: "Not added",
                    slug: "notAdded",
                };
            }
            const [newestSubscription] = subscriptionData.sort((a, b) =>
                a.productExpiryTimestamp < b.productExpiryTimestamp ? 1 : 0
            );
            if (newestSubscription.productExpiryTimestamp > new Date().getTime()) {
                return {
                    name: "Expired",
                    slug: "expired",
                };
            }
            if (newestSubscription.productExpiryTimestamp < moment().subtract(1, "months").unix()) {
                return {
                    name: "Expiring soon",
                    slug: "expiringSoon",
                };
            }
            return {
                name: "Active",
                slug: "active",
            };
        },
    },
};
</script>
