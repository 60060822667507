<template>
    <div class="">
        <h3 class="text-denim-700 text-2xl mt-12 flex items-center font-normal">
            <FeatherWrapper icon="helix" class="h-5 w-5 mr-4"></FeatherWrapper>
            Profile
        </h3>
        <Card class="bg-white mt-6">
            <div class="flex flex-wrap //flex-row-reverse">
                <div class="lg:w-2/5">
                    <div>
                        <p class="my-0 text-grey-600 text-sm">Currently selected</p>
                        <p class="mt-0 mb-0 text-lg font-semibold">
                            {{ $store.getters.profile.name }}
                        </p>
                        <p class="mt-1 mb-0">
                            {{ kitNames[$store.getters.selectedTest.product_sku] || "Upload" }} -
                            {{ selectedTest.barcode }}
                        </p>
                    </div>
                    <div
                        v-if="$store.getters.profiles.length > 1"
                        @click="profileModalOpen = true"
                        class="pt-6 flex items-center cursor-pointer"
                    >
                        <div class="mr-3">
                            <Feather type="repeat" class="h-6 w-6 text-cobalt-500" />
                        </div>
                        <div>
                            <button
                                @click="profileModalOpen = true"
                                class="block text-cobalt-500 font-semibold"
                            >
                                Change selected profile
                            </button>
                            {{ $store.getters.profiles.length - 1 }} other
                            {{ $store.getters.profiles.length - 1 !== 1 ? "profiles" : "profile" }}
                        </div>
                    </div>
                    <router-link
                        :to="{ name: 'activate' }"
                        class="font-semibold flex items-center pt-8"
                    >
                        <FeatherWrapper icon="plus" class="mr-2" />
                        Add another profile
                    </router-link>
                </div>
                <div v-if="kitSteps" class="lg:w-3/5 ml-auto pt-16 lg:pt-0">
                    <p class="my-0 text-grey-600 text-sm">Tests</p>
                    <div class="flex flex-wrap -mx-4">
                        <TestStatus
                            class="w-full xl:w-1/2"
                            v-if="
                                !kitTests.autosomal ||
                                (kitTests.autosomalStarter.purchased &&
                                    kitTests.autosomal.status !== 'updating' &&
                                    !kitTests.autosomal.complete)
                            "
                            @click.native="handleTestClick('autosomalStarter')"
                            :status="autosomalStarterStatus.name"
                        >
                            <template #name>
                                <div class="flex items-center">
                                    <FeatherWrapper icon="helix" class="mr-2" />
                                    Autosomal Taster
                                </div>
                            </template>
                            <template v-slot:status="{ colours }">
                                <p
                                    class="my-0 p-2 inline-block rounded font-semibold text-sm"
                                    :class="`${colours.bgClass} ${colours.textClass}`"
                                    style="white-space: nowrap"
                                >
                                    {{ autosomalStarterStatus.name }}
                                </p>
                            </template>
                        </TestStatus>
                        <TestStatus
                            class="w-full xl:w-1/2"
                            @click.native="handleTestClick('autosomal')"
                            :status="autosomalStatus.name"
                        >
                            <template #name>
                                <div class="flex items-center">
                                    <FeatherWrapper icon="helix" class="mr-2" />
                                    Autosomal
                                </div>
                            </template>
                            <template v-slot:status="{ colours }">
                                <p
                                    class="my-0 p-2 inline-block rounded font-semibold text-sm"
                                    :class="`${colours.bgClass} ${colours.textClass}`"
                                    style="white-space: nowrap"
                                >
                                    {{ autosomalStatus.name }}
                                </p>
                            </template>
                        </TestStatus>
                        <TestStatus
                            class="w-full xl:w-1/2"
                            v-if="kitTests.autosomal.purchased"
                            @click.native="handleTestClick('mtdna')"
                            :status="mtDNAStatus.name"
                        >
                            <template #name>
                                <div class="flex items-center">
                                    <FeatherWrapper icon="female" class="mr-2" />
                                    MT DNA
                                </div>
                            </template>
                            <template v-slot:status="{ colours }">
                                <p
                                    class="my-0 p-2 inline-block rounded font-semibold text-sm"
                                    :class="`${colours.bgClass} ${colours.textClass}`"
                                    style="white-space: nowrap"
                                >
                                    {{ mtDNAStatus.name }}
                                </p>
                            </template>
                        </TestStatus>
                        <TestStatus
                            class="w-full xl:w-1/2"
                            v-if="kitTests.autosomal.purchased"
                            @click.native="handleTestClick('ydna')"
                            :status="yDNAStatus.name"
                        >
                            <template #name>
                                <div class="flex items-center">
                                    <FeatherWrapper icon="male" class="mr-2" />
                                    Y DNA
                                </div>
                            </template>
                            <template v-slot:status="{ colours }">
                                <p
                                    class="my-0 p-2 inline-block rounded font-semibold text-sm"
                                    :class="`${colours.bgClass} ${colours.textClass}`"
                                    style="white-space: nowrap"
                                >
                                    {{ yDNAStatus.name }}
                                </p>
                            </template>
                        </TestStatus>
                        <TestStatus
                            class="w-full xl:w-1/2"
                            v-if="
                                buildVersion !== 'dnanalysis' && !useNewWellbeing &&
                                (!kitTests.injuryRisk ||
                                    (kitTests.injuryRisk &&
                                        kitTests.injuryRisk.status !== 'updating' &&
                                        !kitTests.injuryRisk.complete))
                            "
                            @click.native="handleTestClick('strength')"
                            :status="wellbeingStarterStatus.name"
                        >
                            <template #name>
                                <div class="flex items-center">
                                    <FeatherWrapper icon="heart" class="mr-2" />
                                    Wellbeing Taster
                                </div>
                            </template>
                            <template v-slot:status="{ colours }">
                                <p
                                    class="my-0 p-2 inline-block rounded font-semibold text-sm"
                                    :class="`${colours.bgClass} ${colours.textClass}`"
                                    style="white-space: nowrap"
                                >
                                    {{ wellbeingStarterStatus.name }}
                                </p>
                            </template>
                        </TestStatus>
                        <TestStatus
                            v-if="buildVersion !== 'dnanalysis' && !useNewWellbeing"
                            class="w-full xl:w-1/2"
                            @click.native="handleTestClick('injuryRisk')"
                            :status="wellbeingStatus.name"
                        >
                            <template #name>
                                <div class="flex items-center">
                                    <FeatherWrapper icon="heart" class="mr-2" />
                                    Wellbeing
                                </div>
                            </template>
                            <template v-slot:status="{ colours }">
                                <p
                                    class="my-0 p-2 inline-block rounded font-semibold text-sm"
                                    :class="`${colours.bgClass} ${colours.textClass}`"
                                    style="white-space: nowrap"
                                >
                                    {{ wellbeingStatus.name }}
                                </p>
                            </template>
                        </TestStatus>
                        <TestStatus
                            class="w-full xl:w-1/2"
                            @click.native="handleTestClick('archaic')"
                            :status="archaicStatus.name"
                        >
                            <template #name>
                                <div class="flex items-center">
                                    <FeatherWrapper icon="spear" class="mr-2" />
                                    Neanderthal
                                </div>
                            </template>
                            <template v-slot:status="{ colours }">
                                <p
                                    class="my-0 p-2 inline-block rounded font-semibold text-sm"
                                    :class="`${colours.bgClass} ${colours.textClass}`"
                                    style="white-space: nowrap"
                                >
                                    {{ archaicStatus.name }}
                                </p>
                            </template>
                        </TestStatus>
                        <TestStatus
                            class="w-full xl:w-1/2"
                            @click.native="handleTestClick('viking')"
                            :status="vikingStatus.name"
                        >
                            <template #name>
                                <div class="flex items-center">
                                    <FeatherWrapper icon="viking-shield" class="mr-2" />
                                    Viking
                                </div>
                            </template>
                            <template v-slot:status="{ colours }">
                                <p
                                    class="my-0 p-2 inline-block rounded font-semibold text-sm"
                                    :class="`${colours.bgClass} ${colours.textClass}`"
                                    style="white-space: nowrap"
                                >
                                    {{ vikingStatus.name }}
                                </p>
                            </template>
                        </TestStatus>
                        <TestStatus
                            class="w-full xl:w-1/2"
                            @click.native="handleTestClick('classical')"
                            :status="classicalStatus.name"
                        >
                            <template #name>
                                <div class="flex items-center">
                                    <FeatherWrapper icon="classical" class="mr-2" />
                                    Classical
                                </div>
                            </template>
                            <template v-slot:status="{ colours }">
                                <p
                                    class="my-0 p-2 inline-block rounded font-semibold text-sm"
                                    :class="`${colours.bgClass} ${colours.textClass}`"
                                    style="white-space: nowrap"
                                >
                                    {{ classicalStatus.name }}
                                </p>
                            </template>
                        </TestStatus>
                        <TestStatus
                            class="w-full xl:w-1/2"
                            @click.native="handleTestClick('familyMatching')"
                            :status="familyMatchingStatus.name"
                        >
                            <template #name>
                                <div class="flex items-center">
                                    <FeatherWrapper icon="users" class="mr-2" />
                                    Family matching
                                </div>
                            </template>
                            <template v-slot:status="{ colours }">
                                <p
                                    class="my-0 p-2 inline-block rounded font-semibold text-sm"
                                    :class="`${colours.bgClass} ${colours.textClass}`"
                                    style="white-space: nowrap"
                                >
                                    {{ familyMatchingStatus.name }}
                                </p>
                            </template>
                        </TestStatus>
                        <VitaminStatus />
                        <HealthProfileStatus />
                    </div>
                    <p class="text-sm my-0">Click each test for more details</p>
                </div>
            </div>
            <a
                href="https://support.livingdna.com/hc/en-us/articles/360011200560-How-do-I-switch-between-profiles-on-my-account-"
                target="_blank"
                rel="noopener"
                class="flex items-center mt-8 font-semibold text-cobalt-500"
            >
                To learn more about profiles click here
                <Feather type="chevron-right" class="h-5 w-5 mt-px" />
            </a>
        </Card>
        <Modal :open="modalOpen" width="5xl" @state-change="modalOpen = $event">
            <div v-if="test" class="px-6">
                <h1 class="mt-16 text-xl text-grey-700">Your {{ test.name }} test</h1>
                <component
                    class="pb-4"
                    :is="test.unpurchasedComponent[shortBarcode]"
                    v-if="!test.purchased"
                />
                <table v-if="steps" class="text-lg font-semibold mb-12 w-full">
                    <thead>
                        <tr class="text-base text-grey-600">
                            <td colspan="3">Status</td>
                            <td class="hidden md:table-cell">Usually takes</td>
                        </tr>
                    </thead>
                    <CompletedRow
                        :key="step.name"
                        v-for="step in steps.completeSteps"
                        :step="step"
                    />
                    <InProgressRow
                        :key="step.name"
                        v-for="step in steps.activeSteps"
                        :step="step"
                    />
                    <IncompleteRow
                        :key="step.name"
                        v-for="step in steps.incompleteSteps"
                        :step="step"
                    />
                    <FailedRow :key="step.name" v-for="step in steps.failedSteps" :step="step" />
                </table>
            </div>
        </Modal>
        <Modal :open="profileModalOpen" width="5xl" @state-change="profileModalOpen = $event">
            <div class="px-6">
                <h1 class="mt-16 text-xl text-grey-700">Select a profile</h1>

                <table class="hidden md:table mb-8 w-full">
                    <tr
                        @click="handleProfileClick(kit)"
                        :key="kit.barcode"
                        v-for="kit in mergedTestAndProfile"
                    >
                        <td class="py-4">
                            <img
                                :src="getAvatar(kit)"
                                :alt="`${kit.profile.name}'s avatar`"
                                class="rounded-full h-12 w-12 shadow-drama"
                            />
                        </td>
                        <td>
                            <div class="text-lg">{{ kit.profile.name }}</div>
                            <div class="text-grey-600">
                                <span
                                    v-if="kit.permission.type === 'OWNER'"
                                    class="flex items-center"
                                >
                                    <Feather type="user" class="h-4 w-4 mr-2" />
                                    You own this profile
                                </span>
                                <span
                                    v-if="kit.permission.type === 'MANAGER'"
                                    class="flex items-center"
                                >
                                    <Feather type="briefcase" class="h-4 w-4 mr-2" />
                                    You manage this profile
                                </span>
                                <span v-if="kit.permission.type === 'PARENT'">
                                    This is your child's profile
                                </span>
                            </div>
                        </td>
                        <td>
                            {{ kitNames[kit.product_sku] || "Digital upload" }}
                            <div class="flex items-center text-grey-600">
                                <!--                            <Feather type="briefcase" class="h-4 w-4 mr-2" />-->
                                {{ kit.barcode }}
                            </div>
                        </td>
                        <td class="text-right">
                            <button
                                v-if="kit.permission.isDefault"
                                disabled="disabled"
                                class="rounded border border-grey-700 px-5 py-1 cursor-default"
                            >
                                Currently selected
                            </button>
                            <button
                                @click="handleProfileClick(kit)"
                                v-else
                                class="rounded bg-cobalt-500 text-white px-4 py-1"
                            >
                                Select
                            </button>
                        </td>
                    </tr>
                </table>

                <div
                    class="md:hidden px-6 py-6 border-b border-grey-200"
                    @click="handleProfileClick(kit)"
                    v-for="kit in mergedTestAndProfile"
                >
                    <img
                        :src="getAvatar(kit)"
                        :alt="`${kit.profile.name}'s avatar`"
                        class="rounded-full h-12 w-12 shadow-drama"
                    />
                    <div class="text-lg pt-4">{{ kit.profile.name }}</div>
                    <div class="text-grey-600 pb-4">
                        <span v-if="kit.permission.type === 'OWNER'" class="flex items-center">
                            <Feather type="user" class="h-4 w-4 mr-2" />
                            You own this profile
                        </span>
                        <span v-if="kit.permission.type === 'MANAGER'" class="flex items-center">
                            <Feather type="briefcase" class="h-4 w-4 mr-2" />
                            You manage this profile
                        </span>
                        <span v-if="kit.permission.type === 'PARENT'">
                            This is your child's profile
                        </span>
                    </div>
                    <div class="pb-4">
                        {{ kitNames[kit.product_sku] || "Digital upload" }}
                        <div class="flex items-center text-grey-600">
                            {{ kit.barcode }}
                        </div>
                    </div>
                    <div>
                        <button
                            v-if="kit.permission.isDefault"
                            disabled="disabled"
                            class="rounded border border-grey-700 px-5 py-1 cursor-default"
                        >
                            Currently selected
                        </button>
                        <button
                            @click="handleProfileClick(kit)"
                            v-else
                            class="rounded bg-cobalt-500 text-white px-4 py-1"
                        >
                            Select
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import { last } from "lodash";
import { kitSteps, kitTestSteps, upgradeSteps } from "./kitMachine";
import { setDefaultProfile, setTest } from "@/mixins/profileSwitching";
import { kitNames } from "@/services/kitService";
import { getTestInformation } from "@/services/testService";
import { useNewWellbeing } from "@/services/health";
import defaultAvatar from "@/assets/images/Avatar-Anon.png";
import Card from "@/components/cards/Card.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import TestStatus from "./TestStatus.vue";
import CompletedRow from "@/views/dashboard/StatusBar/CompletedRow.vue";
import InProgressRow from "./StatusBar/InProgressRow.vue";
import IncompleteRow from "@/views/dashboard/StatusBar/IncompleteRow.vue";
import FailedRow from "@/views/dashboard/StatusBar/FailedRow.vue";
import VitaminStatus from "@/views/dashboard/StatusBar/VitaminStatus.vue";
import HealthProfileStatus from "@/views/dashboard/StatusBar/HealthProfileStatus.vue";
import Modal from "@/components/Modal.vue";

export default {
    components: {
        FailedRow,
        IncompleteRow,
        CompletedRow,
        InProgressRow,
        FeatherWrapper,
        Card,
        TestStatus,
        VitaminStatus,
        Modal,
        HealthProfileStatus,
    },
    mixins: [setDefaultProfile, setTest],
    data() {
        return {
            useNewWellbeing,
            steps: undefined,
            test: undefined,
            kitSteps,
            tests: {
                autosomal: {
                    name: "Autosomal",
                    key: "autosomal",
                },
                mtdna: {
                    name: "MT DNA",
                    key: "mtdna",
                },
            },
            kitNames,
        };
    },
    setup() {
        const modalOpen = ref(false);
        const profileModalOpen = ref(false);
        return { modalOpen, profileModalOpen };
    },
    computed: {
        ...mapGetters(["selectedTest", "kitTests", "accountTests", "profiles", "buildVersion"]),
        mergedTestAndProfile() {
            return this.accountTests.map((test) => {
                const correspondingProfile = this.profiles.find(
                    (profile) => profile.profile.barcode === test.barcode
                );
                return {
                    ...correspondingProfile,
                    ...test,
                };
            });
        },
        shortBarcode() {
            if (this.selectedTest.barcode.substr(0, 1) === "U") {
                return "ld";
            }
            return this.selectedTest.barcode.substr(0, 2).toLowerCase();
        },
        autosomalStarterStatus() {
            const test = this.kitTests.autosomalStarter;
            return this.getLatestStatusForTest(test);
        },
        autosomalStatus() {
            const test = this.kitTests.autosomal;
            return this.getLatestStatusForTest(test);
        },
        mtDNAStatus() {
            const test = this.kitTests.mtdna;
            return this.getLatestStatusForTest(test);
        },
        yDNAStatus() {
            const test = this.kitTests.ydna;
            return this.getLatestStatusForTest(test);
        },
        familyMatchingStatus() {
            const test = this.kitTests.familyMatching;
            return this.getLatestStatusForTest(test);
        },
        wellbeingStarterStatus() {
            const test = this.kitTests.strength;
            return this.getLatestStatusForTest(test);
        },
        wellbeingStatus() {
            const test = this.kitTests.injuryRisk;
            return this.getLatestStatusForTest(test);
        },
        vikingStatus() {
            const test = this.kitTests.viking;
            return this.getLatestStatusForTest(test);
        },
        classicalStatus() {
            const test = this.kitTests.classical;
            return this.getLatestStatusForTest(test);
        },
        archaicStatus() {
            const test = this.kitTests.archaic;
            return this.getLatestStatusForTest(test);
        },
    },
    methods: {
        getAvatar(kit) {
            return kit.profile.photo || defaultAvatar;
        },
        getLatestStatusForTest(test) {
            const stepsForTest = this.getStepsForTest(test);

            if (!stepsForTest) {
                console.error("No steps found");
            }
            if (stepsForTest.useIncomplete) {
                return last(stepsForTest.incompleteSteps);
            }
            if (stepsForTest.activeSteps && stepsForTest.activeSteps.length !== 0) {
                return last(stepsForTest.activeSteps);
            }
            if (stepsForTest.failedSteps && stepsForTest.failedSteps.length !== 0) {
                return last(
                    stepsForTest.failedSteps.map((step) => ({
                        ...step,
                        name: step.name === "Cancelled" ? step.name : `${step.name} failed`,
                    }))
                );
            }
            if (stepsForTest.completeSteps && stepsForTest.completeSteps.length !== 0) {
                return last(stepsForTest.completeSteps);
            }

            return stepsForTest.incompleteSteps[0];
        },
        getStepsForTest(test) {
            // todo - what's the cleanest way of preventing stuff for tests that don't exist

            if (test.type === "familyMatching") {
                const testKey = test.status || "notOptedIn";

                return kitTestSteps[this.shortBarcode].other.familyMatching[testKey];
            }
            if (!test.inOriginalBundle) {
                return upgradeSteps[test.status || "notAdded"];
            }
            if (test.type === "ydna" && test.status === "noData") {
                return kitTestSteps.ld.other.yNotAvailable;
            }
            if (this.selectedTest.raw_status === "completed" && test.status === "completed") {
                return kitSteps[this.shortBarcode][this.selectedTest.raw_status];
            }
            if (this.selectedTest.raw_status === "completed" && test.status !== "completed") {
                return kitTestSteps[this.shortBarcode][test.status];
            }
            if (this.selectedTest.raw_status !== "complete") {
                return kitSteps[this.shortBarcode][this.selectedTest.raw_status];
            }
            console.warn("Could not display modal properly", {
                rawStatus: this.selectedTest.raw_status,
                autosomalStarterStatus: test.status,
            });
            return "";
        },
        handleTestClick(test) {
            const fullTest = this.kitTests[test];

            this.steps = this.getStepsForTest(fullTest);
            console.log(this.steps);
            this.test = {
                name: getTestInformation(fullTest).name,
                unpurchasedComponent: getTestInformation(fullTest).unpurchasedComponent,
                purchased: fullTest.purchased,
            };
            console.log(this.test);

            this.modalOpen = true;
        },
        async handleProfileClick(profile) {
            await Promise.all([
                this.setDefaultProfile(profile.barcode),
                this.setTest(profile.barcode),
            ]);
            await this.$store.dispatch("getProfiles");
            this.modalOpen = false;
        },
    },
};
</script>
