<template>
    <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <ul
            role="list"
            class="divide-y divide-grey-200"
            v-if="mergedTestAndProfile.length > 1 || patient"
        >
            <li v-if="patient">
                <div class="block hover:bg-grey-50">
                    <div class="flex items-center px-4 py-4 sm:px-6">
                        <div class="min-w-0 flex-1 flex items-center">
                            <div class="flex-shrink-0">
                                <Avatar class="h-12 w-12" :path="null" />
                            </div>
                            <div
                                class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4 items-center"
                            >
                                <div class="space-y-2">
                                    <p class="text-sm font-medium text-grey-700 truncate m-0">
                                        {{ patient.firstName }} {{ patient.lastName }}
                                    </p>
                                    <div class="mt-2 flex items-center text-sm text-grey-600 m-0">
                                        <span class="flex items-center">
                                            <Feather type="user" class="h-4 w-4 mr-1" />
                                            You own this profile
                                        </span>
                                    </div>
                                </div>
                                <div class="hidden md:block">
                                    <div class="space-y-2">
                                        <p class="text-sm text-grey-700 m-0">Health profile</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button
                                @click="handleProfileClick('patient')"
                                class="rounded bg-cobalt-500 text-white px-4 py-1"
                            >
                                Select
                            </button>
                        </div>
                    </div>
                </div>
            </li>
            <li v-for="kit in mergedTestAndProfile" :key="kit.barcode">
                <div class="block hover:bg-grey-50">
                    <div class="flex items-center px-4 py-4 sm:px-6">
                        <div class="min-w-0 flex-1 flex items-center">
                            <div class="flex-shrink-0">
                                <Avatar class="h-12 w-12" :path="kit.profile.photo" />
                            </div>
                            <div
                                class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4 items-center"
                            >
                                <div class="space-y-2">
                                    <p class="text-sm font-medium text-grey-700 truncate m-0">
                                        {{ kit.profile.name }}
                                    </p>
                                    <div class="mt-2 flex items-center text-sm text-grey-600 m-0">
                                        <span
                                            v-if="kit.permission.type === 'OWNER'"
                                            class="flex items-center"
                                        >
                                            <Feather type="user" class="h-4 w-4 mr-1" />
                                            You own this profile
                                        </span>
                                        <span
                                            v-if="kit.permission.type === 'MANAGER'"
                                            class="flex items-center"
                                        >
                                            <Feather type="briefcase" class="h-4 w-4 mr-1" />
                                            You manage this profile
                                        </span>
                                        <span v-if="kit.permission.type === 'PARENT'">
                                            This is your child's profile
                                        </span>
                                    </div>
                                </div>
                                <div class="hidden md:block">
                                    <div class="space-y-2">
                                        <p class="text-sm text-grey-700 m-0">
                                            {{ kitNames[kit.product_sku] || "Digital upload" }}
                                        </p>
                                        <p class="mt-2 flex items-center text-sm text-grey-600 m-0">
                                            {{ kit.barcode }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button
                                v-if="kit.permission.isDefault"
                                disabled="disabled"
                                class="rounded border border-grey-700 px-5 py-1 cursor-default"
                            >
                                Current
                            </button>
                            <button
                                @click="handleProfileClick(kit)"
                                v-else
                                class="rounded bg-cobalt-500 text-white px-4 py-1"
                            >
                                Select
                            </button>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div v-else class="mt-6">
            As you only have one profile you aren't able to switch. You might want to add more
            profiles in order to manage a family member's DNA or upload your DNA from other testing
            sites.
            <div class="flex space-x-4 mt-4">
                <router-link
                    :to="{ name: 'who-does-this-dna-belong-to' }"
                    class="bg-cobalt-500 hover:bg-cobalt-700 rounded w-full py-2 px-4 text-white font-semibold block w-full text-center"
                >
                    <span class="text-white">Upload DNA</span>
                </router-link>
                <router-link
                    to="/kit-activation/enter-code"
                    class="border-2 border-cobalt-500 rounded w-full py-2 px-4 text-cobalt-500 font-semibold block w-full text-center"
                >
                    Activate kit
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import store from "@/store/store";
import defaultAvatar from "@/assets/images/Avatar-Anon.png";
import { kitNames } from "@/services/kitService";
import Avatar from "@/components/Avatar.vue";
import { getPatient } from "@/services/health";

export default {
    components: {
        Avatar,
    },
    setup(_, { emit }) {
        const mergedTestAndProfile = store.getters.accountTests.map((test) => {
            const correspondingProfile = store.getters.profiles.find(
                (profile) => profile.profile.barcode === test.barcode
            );
            return {
                ...correspondingProfile,
                ...test,
            };
        });
        async function handleProfileClick(kit) {
            if (kit === "patient") {
                location.href = "/health";
                return;
            }

            // Update the selected profile and related tests
            await Promise.all([
                store.dispatch("setDefaultProfile", kit.permission.id),
                store.dispatch("SET_TEST_FROM_TESTS", kit.profile.barcode),
                store.dispatch("SET_KIT_TESTS", kit.profile.barcode),
            ]);

            // Fetch the build version for the selected profile
            await store.dispatch("fetchBuildVersion", kit.profile.barcode);

            // Fetch profiles to ensure state consistency
            await store.dispatch("getProfiles");

            // Emit profile-changed event to inform parent components
            emit("profile-changed");
        }
        const patient = ref(false);
        onMounted(async () => {
            const account = store.getters.account;
            const patientResult = await getPatient(account);
            if (patientResult) {
                // @ts-ignore
                patient.value = {
                    firstName: account.firstName,
                    lastName: account.lastName,
                };
            }
        });
        return {
            mergedTestAndProfile,
            kitNames,
            handleProfileClick,
            patient,
        };
    },
};
</script>
