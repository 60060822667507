<template>
    <div>
        <WellbeingNotPurchased v-if="!hasPurchasedAnyWellbeing" />
        <TransferQcFailed v-else-if="hasWellbeingFailed" />
        <template v-else>
            <div v-if="loading">
                <PageLoader class="mt-12"> Loading your wellbeing results </PageLoader>
            </div>
            <template v-else>
                <portal to="navigation">
                    <NavBar />
                </portal>

                <transition name="slide-left" mode="out-in">
                    <router-view :key="profile.barcode" :traits="reports" />
                </transition>
            </template>
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import TransferQcFailed from "@/views/wellbeing/TransferQcFailed.vue";
import WellbeingNotPurchased from "@/views/wellbeing/WellbeingNotPurchased.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper";
import NavBar from "./WellbeingNavBar.vue";
import PageLoader from "@/components/PageLoader.vue";

export default {
    components: { FeatherWrapper, WellbeingNotPurchased, TransferQcFailed, NavBar, PageLoader },
    data() {
        return {
            reports: [],
            loading: true,
        };
    },
    async created() {
        try {
            console.log(this.profile.isUploader);
            if (!this.profile.isUploader) {
                await this.$store.dispatch(
                    "fetchWellbeingData",
                    this.$store.getters.profile.barcode
                );
                this.reports = this.$store.getters.wellbeing;
            }
            this.loading = false;
        } catch (e) {
            console.log(e);
            this.loading = false;
        }
    },
    watch: {
        async profile() {
            try {
                this.loading = true;
                if (!this.profile.isUploader) {
                    await this.$store.dispatch(
                        "fetchWellbeingData",
                        this.$store.getters.profile.barcode
                    );
                    this.reports = this.$store.getters.wellbeing;
                }
                this.loading = false;
            } catch (e) {
                console.log(e);
                this.loading = false;
            }
        },
    },
    computed: {
        ...mapGetters(["kitTests", "profile", "buildVersion"]),
        hasPurchasedAnyWellbeing() {
            return !!Object.values(this.kitTests).some(
                (t) => t.group === "wellbeing" && t.purchased
            );
        },
        hasWellbeingFailed() {
            return this.$store.getters.kitTests.strength.status === "failed";
        },
    },
};
</script>

//
