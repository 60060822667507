import { createApp } from "vue";
import Vuelidate from "vuelidate";
import { createAuth } from "@websanova/vue-auth";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js";
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js";
import axios from "axios";
import "font-awesome/css/font-awesome.css";
import endpoints from "@/endpoints";
import VueAuthBearer from "@/bearer";
import App from "@/App.vue";
import { router } from "@/router/router";
import store from "@/store/store";
import VueFeather from "vue-feather";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import Spacer from "@/components/utilities/Spacer.vue";
import PortalVue from "portal-vue";
import TextLink from "@/components/TextLink.vue";
import TextLinkRight from "@/components/TextLinkRight.vue";
import { createGtm } from "@gtm-support/vue-gtm";
import AppImage from "@/components/AppImage.vue";
import { createPinia } from "pinia";
import PageLoader from "@/components/PageLoader.vue";
import "open-sans-fontface";
import "@/styles/main.scss";

const app = createApp(App);

app.config.compilerOptions.isCustomElement = (tag) => tag === "trix-editor";

const pinia = createPinia();
app.use(store);
app.use(router);
app.use(PortalVue);
app.use(Vuelidate);
app.component("AppImage", AppImage);
app.use(pinia);

app.use(
    createGtm({
        id: "GTM-KMZKDB",
        enabled: process.env.NODE_ENV === "production",
        debug: false,
        vueRouter: router,
    }),
);

app.component("Feather", VueFeather);
app.component("FeatherWrapper", FeatherWrapper);
app.component("Space", Spacer);
app.component("TextLink", TextLink);
app.component("TextLinkRight", TextLinkRight);
app.component("PageLoader", PageLoader);

var auth = createAuth({
    plugins: {
        http: axios,
        router,
    },
    drivers: {
        http: driverHttpAxios,
        auth: VueAuthBearer,
        router: driverRouterVueRouter,
    },
    options: {
        rolesKey: "type",
        notFoundRedirect: { path: "/dashboard" },
        authRedirect: { path: "/signin" },
        loginData: {
            url: endpoints.loginApi,
            method: "POST",
            fetchUser: false,
            staySignedIn: true,
        },
        refreshData: {
            url: endpoints.refreshToken,
            method: "POST",
            enabled: true,
            interval: 30,
        },
        registerData: {
            url: endpoints.account,
            method: "POST",
            redirect: false,
        },
        fetchData: {
            url: endpoints.account,
            method: "GET",
            enabled: false,
        },
        stores: ["storage"],
        tokenDefaultKey: "token",
    },
});

app.use(auth);
app.config.productionTip = false;

const LATEST_VERSION = "V2.16.17";
if (localStorage.getItem("LATEST_VERSION") !== LATEST_VERSION) {
    localStorage.setItem("LATEST_VERSION", LATEST_VERSION);
    window.location.reload(true);
}

if (process.env.NODE_ENV === "production") {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-VH8BNC5BTY";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "G-VH8BNC5BTY");
    };
}

app.mount("#app");
