import { isEmpty, values } from "lodash";
import ProfileService, { isUnder18 } from "@/services/profile";
import loadingHelper from "@/services/store";

const { loadingState, loadingMutations } = loadingHelper("FULL_PROFILE");

const initialState = () => ({
    loadingStatus: null,
    profile: {},
    profiles: [],
    fullProfile: null,
    buildVersion: null, // Add buildVersion to state

});

export default {
    state: initialState(),
    getters: {
        buildVersion: state => state.buildVersion,
        fullProfileLoadingStatus: state => state.loadingStatus,
        profile: state => state.profile,
        profiles: state => state.profiles,
        isFemaleUser: state => state.profile.gender === 2,
        isProfileUnderAge: (s, getters) => isUnder18(getters.profile),
        fullProfile: state => state.fullProfile,
        firstName: state => (state.profile.name ? state.profile.name.split(" ")[0] : ""),
        isUploader: state => state.profile.barcode.substr(0, 2) === "LT",
    },
    mutations: {
        ...loadingMutations,
        setBuildVersion(state, buildVersion) {
            state.buildVersion = buildVersion; // Directly set the fetched value
        },
        setProfile(state, profile) {
            state.profile = { ...profile };
        },
        setProfiles(state, profiles) {
            state.profiles = [...profiles];
        },
        SET_FULL_PROFILE(state, profile) {
            state.fullProfile = profile;
        },
        CLEAR_PROFILE_MODULE(state) {
            Object.assign(state, initialState());
        },
        setProfileAvatar(state, url) {
            state.profile.photo = url;
        },
    },
    actions: {
        async fetchBuildVersion({ commit }) {
            try {
                console.log("getting buildVersion");
                const buildVersion = await ProfileService.getBuildVersion();
                commit("setBuildVersion", buildVersion);
            } catch (error) {
                console.error("Failed to fetch buildVersion:", error);
            }
        },
        async getDefaultProfile({ commit, state }) {
            // if (isEmpty(state.profile)) {
            const profile = await ProfileService.getDefaultProfile();
            commit("setProfile", profile);
            return profile;
            // }
            // return state.profile;
        },
        async getProfiles({ commit, state }) {
            // if (state.profiles.length === 0) {
            const profiles = await ProfileService.getProfiles();
            console.log(profiles, "again");
            commit("setProfiles", values(profiles));
            return values(profiles);
            // }
            // return state.profiles;
        },
        async setDefaultProfile({ commit, state }, id) {
            const data = await ProfileService.setDefaultProfile(id);
            const { profile } = state.profiles.find(({ permission }) => permission.id === id);
            commit("setProfile", profile);
            return data;
        },
        async GET_FULL_PROFILE({ commit }, barcode) {
            commit("SET_FULL_PROFILE", await ProfileService.getFullProfile(barcode));
        },
        async EDIT_FULL_PROFILE({ commit }, { barcode, payload }) {
            try {
                commit(loadingState.PENDING);

                await ProfileService.editProfile(barcode, payload);

                commit(loadingState.SUCCESS);
            } catch (err) {
                console.error(err);
                commit(loadingState.FAILED);
            }
        },
        async EDIT_CONSENTS({ commit }, { barcode, payload }) {
            try {
                commit(loadingState.PENDING);

                await ProfileService.editFamilyMatchingConsent(barcode, payload);

                commit(loadingState.SUCCESS);
            } catch (err) {
                console.error(err);
                commit(loadingState.FAILED);
            }
        },
        async reuploadDnaFile({ commit, state }, { barcode, file }) {
            try {
                return await ProfileService.reuploadDnaFile(barcode, file);
            } catch (e) {
                throw new Error(e);
            }
        },
        setProfileAvatar({ commit }, url) {
            commit("setProfileAvatar", url);
        },
        CLEAR_PROFILE_MODULE({ commit }) {
            commit("CLEAR_PROFILE_MODULE");
        },
    },
};
