/* eslint-disable */
const SPLITNAME = "--REFRESHTOKEN--";

export default {
    request: function (req, token) {
        let splittoken = token ? token.split(SPLITNAME) : [];
        if (splittoken.length === 2) {
            if (req.url === this.options.refreshData.url) {
                req.data = { refresh_token: splittoken[1] };
            } else {
                this.drivers.http.setHeaders.call(this, req, {
                    Authorization: "Bearer " + splittoken[0],
                });
            }
        } else if (splittoken.length === 1) {
            this.drivers.http.setHeaders.call(this, req, {
                Authorization: "Bearer " + token,
            });
        }
    },

    response: function (res) {
        if (res.data.token && res.data.refresh_token) {
            const token = `${res.data.token}${SPLITNAME}${res.data.refresh_token}`;
            return token;
        } else if (res.data.token) {
            return res.data.token;
        }
        return null;
    },
};

