<template>
    <div id="storeItems" class="//py-12 container mx-auto">
        <!-- <h3 class="px-12 w-full text-3xl font-normal text-denim-700 flex items-center mb-10">
            <feather type="shopping-cart" class="cart-icon" />
            <span class="ml-5">Store</span>
        </h3> -->

        <div v-if="status === 'loading'" class="px-12 text-center">
            <PageLoader class="mt-12">
                We are creating your personalised store. <br />This may take a few moments.
            </PageLoader>
        </div>
        <div class="max-w-2xl mx-12" v-else-if="status === 'failed'">
            <div class="rounded-md bg-rose-50 p-4">
                <div class="flex items-center">
                    <Feather
                        type="x-circle"
                        class="h-5 w-5 text-rose-500 flex-shrink-0"
                        aria-hidden="true"
                    />
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-rose-500">
                            There was an issue loading your store. You can try reloading, if the
                            issue persists please contact support
                        </h3>
                    </div>
                </div>
            </div>
            <button
                @click="reloadPage"
                class="py-2 px-4 mt-6 rounded flex items-center justify-center bg-cobalt-500 hover:bg-cobalt-700 text-white font-semibold"
            >
                Reload
            </button>
        </div>
        <div v-else-if="status === 'loaded'">
            <ProductsCallOut v-if="callOutProduct" :call-out-product="callOutProduct" />
            <div class="mt-12">
                <template v-for="(group, key) in productGroups" :key="key">
                    <div
                        class="mb-6"
                        v-if="!(buildVersion === 'dnanalysis' && (key === 'Vitamins supplement' || key === 'Merchandise'))"
                    >
                        <h2
                            v-if="groupHasProductsNotPurchased(group)"
                            class="px-12 text-2xl text-denim-700 font-normal"
                        >
                            {{ key }}
                        </h2>
                        <p
                            v-if="groupHasProductsNotPurchased(group)"
                            class="text-grey-700 text-base px-12 my-0"
                        >
                            {{ groupText(key) }}
                        </p>
                        <div class="px-6 flex flex-wrap">
                            <template v-for="product in group">
                                <div
                                    :key="product.slug"
                                    v-if="!product.purchased"
                                    class="p-6 w-full w-full md:w-1/2 xl:w-1/3 md:min-w-md"
                                >
                                    <product-card
                                        :product="product"
                                        :category="key"
                                        @card-clicked="cardClicked"
                                        class="mb-4 h-full"
                                    >
                                        <template #highlight-text>
                                            {{ salesBanner.highlight_text }}
                                        </template>
                                    </product-card>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import ProductCard from "@/views/store/store-components/product-card.vue";
import { groupBy, first } from "lodash";
import store from "@/store/store";
import PageLoader from "@/components/PageLoader.vue";
import { useSalesBanner } from "@/composables/useSalesBanner";
import { ref } from "vue";
import ProductsCallOut from "./ProductsCallOut.vue";

const portalKits = [
    "Members-Ancestry-Kit",
    "Members-Ancestry-Wellbeing-Kit",
    "Members-Wellbeing-Kit",
];

function productIsSaleable(product, buildVersion) {
    const excludedProductsForBuild38 = ["Wellbeing-Upgrade"];
    const isExcludedForBuild38 =
        buildVersion === "dnanalysis" &&
        (excludedProductsForBuild38.includes(product.slug) || portalKits.includes(product.slug));

    // Allow member kits for everyone except buildVersion "dnanalysis"
    return (
        (product.categories !== "Kit" || portalKits.includes(product.slug)) &&
        !isExcludedForBuild38
    );
}


export default {
    name: "Store.vue",
    data() {
        return {
            status: "idle",
        };
    },
    setup() {
        const salesBanner = ref(null);
        useSalesBanner().then((salesBannerResult) => {
            salesBanner.value = salesBannerResult;
        });
        return { salesBanner };
    },
    components: {
        ProductCard,
        PageLoader,
        ProductsCallOut,
    },
    async beforeRouteEnter(to, from, next) {
        await store.dispatch("GET_REGION");
        if (!store.getters.getRegion) {
            next("/store/set-region");
        } else {
            next();
        }
    },
    computed: {
        ...mapGetters(["accountData", "products", "buildVersion"]),
        firstName() {
            if (this.accountData) {
                return this.accountData.firstName;
            }
            return "";
        },
        saleableProducts() {
            return this.products
                .filter((product) => productIsSaleable(product, this.buildVersion)) // Pass buildVersion explicitly
                .map((product) => ({
                    isOnSale: product.slug.toLowerCase() === this.salesBanner?.product_slug,
                    ...product,
                }));
        },
        productGroups() {
            return groupBy(this.saleableProducts, "categories");
        },
        callOutProduct() {
            return this.products.find((p) => p.slug === "autosomal-upgrade" && !p.purchased);
        },
    },
    methods: {
        async cardClicked(s) {
            const product = this.products.find((p) => p.slug === s);
            if (
                product.dependentProducts
                && product.dependentProducts.length > 0
                && product.locked
            ) {
                const { slug } = first(product.dependentProducts);
                if (slug) {
                    const element = document.getElementById(slug);
                    element.scrollIntoView({ behavior: "smooth", block: "center" });
                    await this.$store.dispatch("setHighlightedProduct", slug);
                }
            } else {
                this.$router.push({ name: "product", params: { slug: s } });
            }
        },
        // unhighlightCards() {
        //     this.productDisplayItems.forEach((dep) => {
        //         const payload = { highlighted: false, slug: dep.slug };
        //         this.$store.commit('setProductDisplayItemHighlightState', payload);
        //     });
        // },
        groupText(group) {
            switch (group) {
                case "Merchandise":
                    return "Take your DNA offline with premium merchandise.";
                case "Digital upgrade":
                    return "Unlock more of your DNA story.";
                case "Kit":
                    return "Purchase additional kits at a discounted price";
                case "Vitamins supplement":
                    return "Purchase a vitamin supplement based on your DNA";
                default:
                    return "";
            }
        },
        groupHasProductsNotPurchased(group) {
            console.log({ group });
            return group.find((product) => !product.purchased);
        },
        reloadPage() {
            window.location.reload();
        },
    },
    async mounted() {
        this.status = "loading";
        try {
            await this.$store.dispatch("getProducts");
            this.status = "loaded";
        } catch (e) {
            this.status = "failed";
        }

        this.storeSub = this.$store.subscribeAction({
            after: async ({ type }) => {
                if (type === "CLEAR_PRODUCTS_MODULE" && this.$route.meta.type === "store") {
                    this.status = "loading";
                    await this.$store.dispatch("getProducts");
                    this.status = "loaded";
                }
            },
        });
    },
    beforeDestroy() {
        this.storeSub();
    },
};
</script>

<style scoped>
.cart-icon {
    height: 1.75rem;
    width: 1.75rem;
}
</style>
